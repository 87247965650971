import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { PackageMetadata } from "@happeo/docs-tools";
import pkg from "../package.json";
import { shortToUnicode, emojisToShort, removeEmojis } from "../src/index";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "emojis"
    }}>{`Emojis`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <h2 {...{
      "id": "emoji-functions"
    }}>{`Emoji functions`}</h2>
    <p>{`This package was created to minimize the amount of code imported with the emojione library. The 3 main functions for emoji handling are `}<inlineCode parentName="p">{`shortToUnicode()`}</inlineCode>{`, `}<inlineCode parentName="p">{`emojisToShort()`}</inlineCode>{`, `}<inlineCode parentName="p">{`removeEmojis()`}</inlineCode>{` and `}<inlineCode parentName="p">{`listAllEmojis()`}</inlineCode>{`.
They can be used in different text environments and to list all emojis.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const text = "This is emoji with short hand :smile: converted to unicode";
const renderText = shortToUnicode(text);

const text2 = "This is unicode 😄 converted to short hand";
const renderText2 = emojisToShort(text2);

const text3 = "This is 😄 has emojis 😄 and they will be removed 😄";
const renderText3 = removeEmojis(text3);
`}</code></pre>
    <Playground __position={1} __code={'<p>\n  {shortToUnicode(\n    \'This is emoji with short hand :smile: converted to unicode\',\n  )}\n</p>\n<p>{emojisToShort(\'This is unicode 😄 converted to short hand\')}</p>\n<p>{removeEmojis(\'This is 😄 has emojis 😄 and they will be removed 😄\')}</p>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      shortToUnicode,
      emojisToShort,
      removeEmojis,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <p>
    {shortToUnicode("This is emoji with short hand :smile: converted to unicode")}
  </p>
  <p>{emojisToShort("This is unicode 😄 converted to short hand")}</p>
  <p>{removeEmojis("This is 😄 has emojis 😄 and they will be removed 😄")}</p>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      